<template>
  <div class="details">
    <vxe-toolbar>
      <template #buttons>
        <el-link icon="el-icon-plus" @click="openDetails" v-if="!disabled"
          >新增明细</el-link
        >
        <el-link icon="el-icon-document" @click="openVerification()"
          >核销资料</el-link
        >
        <el-link
          class="red"
          icon="el-icon-delete"
          @click="clearTable"
          v-if="!disabled"
          >清空</el-link
        >
      </template>
    </vxe-toolbar>
    <vxe-table
      border
      show-overflow
      ref="xTable"
      :data="value"
      @checkbox-change="checkboxChange"
      @checkbox-all="checkboxAll"
    >
      <template v-for="config in columns">
        <!-- 本次核销金额 -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-if="config.field === 'auditApplyAmount'"
        >
          <template #default="{ row, rowIndex }">
            <!-- <el-input-number
              v-model="row[config.field]"
              :precision="2"
              :min="0"
              :max="maxFn(row)"
              :disabled="disabled"
              :controls="false"
              @change="inputAuditApplyAount($event, row, rowIndex)"
            >
            </el-input-number> -->
            <vxe-input
              type="number"
              v-model="row[config.field]"
              :min="0"
              :max="maxFn(row)"
              :disabled="disabled"
              @input="inputAuditApplyAount($event, row, rowIndex)"
            />
          </template>
        </vxe-table-column>
         <!-- 快递单号 ext77 -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext77'"
        >
          <template #default="{ row }">
            <vxe-input
            maxlength="100"
              v-model="row[config.field]"
              :disabled="disabled"
            />
          </template>
        </vxe-table-column>
        <!-- 往来户名称 ext31-->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext31'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled" />
          </template>
        </vxe-table-column>
        <!-- 往来户类型 -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext18'"
        >
          <template #default="{ row}">
            <el-select
              v-model="row[config.field]"
              placeholder="请选择"
              :disabled="disabled"
            >
              <el-option label="其他" value="00000" />
              <el-option label="客户" value="00001" />
              <el-option label="供应商" value="00002" />
            </el-select>
          </template>
        </vxe-table-column>
        <!-- 供应商账户 ext32-->
        <!-- <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext32'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled" />
          </template>
        </vxe-table-column> -->
        <!-- 收款方名称 ext14-->
        <!-- <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext14'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled||!row.ext4" />
          </template>
        </vxe-table-column> -->
        <!-- 开户行 ext15-->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext15'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled||!row.ext4" />
          </template>
        </vxe-table-column>
        <!-- 账号 ext16-->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext16'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled||!row.ext4" />
          </template>
        </vxe-table-column>
        <!-- 开户行行号 ext17-->
        <!-- <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 200"
          v-else-if="config.field === 'ext17'"
        >
          <template #default="{ row }">
            <vxe-input v-model="row[config.field]" :disabled="disabled||!row.ext4" />
          </template>
        </vxe-table-column> -->
        <!-- 备注 -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'remarks'"
        >
          <template #default="{ row }">
            <vxe-input
              type="textarea"
              v-model="row[config.field]"
              :disabled="disabled"
            />
          </template>
        </vxe-table-column>

        <!-- 是否合规 -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext10'"
        >
          <template #default="{ row }">
            <el-select
              v-model="row[config.field]"
              placeholder="请选择"
              disabled
            >
              <el-option label="是" value="Y" />
              <el-option label="否" value="N" />
            </el-select>
          </template>
        </vxe-table-column>
        <!-- 瓶盖回收 -->
       <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext76'"
        >
          <!-- <template #default="{ row }">
            <vxe-input
              type="text"
              v-model="row[config.field]"
              :disabled="formRole === 'update2' ? false : true"
            />
          </template> -->
           <template #default="{ row }">
            <el-select
              v-model="row[config.field]"
              placeholder="请选择"
              :disabled="formRole === 'update2' ? false : true"
            >
              <el-option label="是" value="Y" />
              <el-option label="否" value="N" />
            </el-select>
          </template>
        </vxe-table-column>
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'payType'"
        >
          <template #default="{ row }">
            <el-select
              v-model="row[config.field]"
              placeholder="请选择"
              disabled
            >
              <el-option
                v-for="(item, k) in row.payTypes"
                :key="k"
                :label="item.dictKey"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </template>
        </vxe-table-column>
        <!-- 支付方式是否为：【现金】，若不为现金，不展示【税额】字段 -->
        <!-- <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
          v-else-if="config.field === 'ext60'"
        >
          <template #default="{ row }">
            <vxe-input
              v-if="row.payType == '1'"
              type="number"
              v-model="row[config.field]"
              :min="0"
              :disabled="disabled"
              :max="9999999999"
              @input="inputTax($event,row,rowIndex)"
            />
          </template>
        </vxe-table-column> -->
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 200"
          v-else-if="config.field === 'productLevelCode'"
        >
          <template #default="{ row, rowIndex }">
            <el-select
              v-model="row.productLevelCode"
              filterable
              remote
              placeholder="产品层级"
              @focus="() => remoteMethod('lev', rowIndex)"
              :remote-method="v => remoteMethod('lev', rowIndex, v)"
              @change="v => levelChange(v, rowIndex)"
              :loading="loading"
              clearable
              v-if="!disabled"
              :disabled="row.payType !== '4'"
            >
              <el-option
                v-for="item in row.levelOptions"
                :key="item.value"
                :label="item.productLevelName"
                :value="item.productLevelCode"
              ></el-option>
            </el-select>
            <span v-else>{{ row.productLevelName }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 260"
          v-else-if="config.field === 'replenishmentProductCodes'"
        >
          <template #default="{ row, rowIndex }">
            <el-select
              v-model="row.replenishmentProductCodes"
              filterable
              remote
              reserve-keyword
              placeholder="产品数据"
              @focus="() => remoteMethod('pro', rowIndex)"
              :remote-method="v => remoteMethod('pro', rowIndex, v)"
              :loading="loading"
              clearable
              multiple
              collapse-tags
              v-if="!disabled"
              @change="v => productChange(v, rowIndex)"
              :disabled="row.payType !== '4'"
            >
              <el-option
                v-for="item in row.productOptions"
                :key="item.value"
                :label="item.productName"
                :value="item.productCode"
              ></el-option>
            </el-select>
            <span v-else>{{
              (row.replenishmentProductList || [])
                .map(v => v.productName)
                .join(',')
            }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column
          v-else
          :key="config.id"
          v-bind="config"
          :min-width="config.minWidth || 150"
        ></vxe-table-column>
      </template>
      <vxe-table-column title="操作" fixed="right" width="150" align="center">
        <template #default="{ row, rowIndex }">
          <div class="setting-btn">
            <el-link
              type="primary"
              v-if="row.ext73 === 'Y'"
              @click="executionLookFn(row, rowIndex)"
              >执行查看</el-link
            >
            <!-- <el-link type="primary" @click="openVerification(row)">核销资料</el-link> -->
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row, rowIndex)"
              v-if="columns.length > 0 && !disabled"
            >
              <el-link class="red" slot="reference">删除</el-link>
            </el-popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <div v-if="tastingReqVos.length>0" class="tasting">
      <vxe-table
        border
        show-overflow
        ref="xTable"
        :data="tastingReqVos"
        @checkbox-change="checkboxChange"
        @checkbox-all="checkboxAll"
      >
        <template v-for="config in columns1">
          <!-- 品鉴酒核销数量 -->
          <vxe-table-column
            :key="config.id"
            v-bind="config"
            :min-width="config.minWidth || 150"
            v-if="config.field === 'num'"
          >
            <template #default="{ row, rowIndex }">
              <vxe-input
                type="number"
                v-model="row[config.field]"
                :min="0"
                :max="row.tastingNum"
                :disabled="disabled||!row.isTarget"
                @input="inputAmount($event, row, rowIndex)"
              />
            </template>
          </vxe-table-column>
          <vxe-table-column
            :key="config.id"
            v-bind="config"
            :min-width="config.minWidth || 150"
            v-else-if="config.field === 'payType'"
          >
            <template #default="{ row }">
              <el-select
                v-model="row[config.field]"
                placeholder="请选择"
                disabled
              >
                <el-option
                  v-for="(item, k) in tastingPayTypeList"
                  :key="k"
                  :label="item.dictValue"
                  :value="item.dictCode"
                ></el-option>
              </el-select>
            </template>
          </vxe-table-column>

          <vxe-table-column
            v-else
            :key="config.id"
            v-bind="config"
            :min-width="config.minWidth || 150"
          ></vxe-table-column>
        </template>
        <vxe-table-column title="操作" fixed="right" width="150" align="center">
            <template #default="{ row, rowIndex }">
              <div class="setting-btn">
                <el-link
                  type="primary"
                  @click="showModal(row, rowIndex)"
                  >扫码记录</el-link
                >
              </div>
            </template>
          </vxe-table-column>
      </vxe-table>
    </div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <Modal
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      @onClose="closeModal"
    >
      <component
        ref="modalForm"
        :is="formName"
        :formConfig="formConfig"
        @getResourceList="getResourceList"
        @setFielValue="setFielValue"
        @setPersonValue="setPersonValue"
      />
      <!-- <Material ref="modalForm" :formConfig="formConfig" @setFielValue="setFielValue" /> -->
      <div class="dialog-footer">
        <el-button type="danger" v-if="isScan" @click="confirmResource">确定</el-button>
        <el-button icon="el-icon-close" type="danger" @click="closeModal"
          >关闭</el-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from 'xe-utils';
import request from '../../../../../../../utils/request';
import SelectConfig from '../../../../../../../components/select_org_cus_ter/select_config.vue';
import Modal from '../../../../../../../components/modal';
import Material from './material.vue';
import Execution from './execution.vue';
import ScanRecord from './scan_record.vue';

export default {
  name: 'edit_details',
  props: {
    formRole: String,
    value: Array,
    getFieldValue: Function,
    disabled: Boolean,
    code: String,
    tastingReqVos: Array,
    auditId: String,
  },
  data() {
    return {
      chooseIndex: null,
      chooseData: [],
      columns: [],
      columns1: [],
      params: {
        parentCode: 'CRM20201126000000098',
        functionCode: 'tpm_act_details_list',
      },
      selectRow: [],
      modalConfig: {
        width: '60%',
        title: '上传核销资料',
        type: 'Modal',
        visible: false,
      },
      formConfig: {},
      formName: 'Execution',
      loading: false,
      tastingPayTypeList: [],
      isScan: true,
    };
  },
  components: {
    SelectConfig,
    Modal,
    Material,
    Execution,
    ScanRecord,
  },
  watch: {
    formRole(newVal) {
      this.formRole = newVal;
    },
    value() {
      // if (this.value.length > 0) {
      //   if (((this.value || [])[0] || {}).isOpen === '1' && ((this.value || [])[0] || {}).tastingPayType === '1') {
      //     this.getTastingColumns1();
      //     this.getTastingColumns2();
      //   }
      //   // if (((this.value || [])[0] || {}).fineCode === 'XL267') {
      //   //   if (((this.value || [])[0] || {}).tastingTypeCode === 'GROUP' || ((this.value || [])[0] || {}).tastingTypeCode === 'TASTING') {
      //   //     this.getTastingColumns1();
      //   //     this.getTastingColumns2();
      //   //   }
      //   // }
      // }
    },
    tastingReqVos() {
      if (this.tastingReqVos.length > 0) {
        this.getTastingColumns1();
        this.getTastingColumns2();
      }
    },
  },
  created() {
    this.getColumns();
    this.getTastingPayType();
  },
  methods: {
    // 输入税额
    // inputTax(val, row, index) {
    //   const rows = row;
    //   rows.ext60 = val.toString().match(/^\d+(?:\.\d{0,2})?/).input;
    //   if (Number(row.ext60) > Number(row.auditApplyAmount)) {
    //     this.$message.error('税额不能大于核销金额');
    //   }
    //   this.value[index] = rows;
    // },
    // 获取选中的资料
    getResourceList(val) {
      this.chooseData = val;
    },
    // 确认资料
    async confirmResource() {
      this.modalConfig.visible = false;
      this.formConfig.row.auditFileRespVos = this.chooseData;
      if (this.chooseIndex || this.chooseIndex === 0) {
        let detailVos = [];
        const tastingReqVos = [];
        detailVos.push(this.value[this.chooseIndex]);
        tastingReqVos.push(this.tastingReqVos[this.chooseIndex]);
        detailVos = detailVos.map((v) => {
          const obj = v;
          obj.auditFileRespVos = obj.auditFileRespVos.map((m) => {
            const obj1 = m;
            obj1.ext3 = obj1.ncross;
            obj1.ext4 = obj1.nativeNum;
            obj1.ext5 = obj1.tastingNum;
            return obj1;
          });
          return obj;
        });
        await request.post(
          '/tpm/tpmAuditExt/queryTastingNum',
          {
            detailVos,
            tastingReqVos,
          },
        ).then((res) => {
          console.log(this.chooseIndex);
          console.log(this.tastingReqVos);
          if (res.success) {
            this.tastingReqVos[this.chooseIndex].isTarget = res.result[0].isTarget;
            this.tastingReqVos[this.chooseIndex].tastingNum = res.result[0].scanNum - res.result[0].nativeNum;
            this.tastingReqVos[this.chooseIndex].num = res.result[0].tastingNum;
            this.tastingReqVos[this.chooseIndex].amount = Number(res.result[0].tastingNum || 0) * Number(this.tastingReqVos[this.chooseIndex].tastingPrice);
            this.tastingReqVos[this.chooseIndex].nativeNum = res.result[0].nativeNum;
            this.tastingReqVos[this.chooseIndex].nativeAmount = Number(res.result[0].nativeNum || 0) * Number(this.tastingReqVos[this.chooseIndex].tastingPrice);
          }
        });
        this.$emit('changeTasting', this.tastingReqVos);
      }
    },
    // 计算除法浮点
    divide(num1, num2) {
      let t1 = 0;
      let t2 = 0;
      let r1 = 0;
      let r2 = 0;
      let result = 0;
      if (!(num1 % 1 !== 0)) {
        t1 = 0;
      } else {
        t1 = num1.toString().split('.')[1].length;
      }
      if (!(num2 % 1 !== 0)) {
        t2 = 0;
      } else {
        t2 = num2.toString().split('.')[1].length;
      }
      r1 = Number(num1.toString().replace('.', ''));
      r2 = Number(num2.toString().replace('.', ''));
      result = (r1 / r2) * (10 ** (t2 - t1));
      result = result.toString().match(/^\d+(?:\.\d{0,3})?/);
      return Number(result);
    },
    // 计算产品数量
    inputAuditApplyAount(val, row, index) {
      console.log(val);
      const rows = row;
      // rows.ext11 = (val / row.ext21).toString().match(/^\d+(?:\.\d{0,2})?/).input;
      // rows.ext11 = _.floor(rows.ext11, 3);
      rows.ext11 = this.divide(val, row.ext21) || null;
      this.value[index] = rows;
    },
    // 计算品鉴酒金额
    inputAmount(val, row, index) {
      console.log(val);
      const rows = row;
      rows.amount = Number(val) * Number(row.tastingPrice);
      this.tastingReqVos[index] = rows;
    },
    // 设置最高核销金额
    maxFn(row) {
      console.log(this.code);
      const num = Number(row.actDetailApplyAmount || 1) * Number(row.extraAuditRatio || 0)
        + Number(row.actDetailApplyAmount || 0)
        - Number(row.alreadyAuditAmount || 0);
      // const nums = num.toFixed(2);
      // return Number(nums);
      return num.toFixed(2);
    },

    // 获取表头配置
    async getColumns() {
      const res = await request.post('/mdm/mdmtableconfig/query', this.params);
      if (res.success) {
        this.columns = res.result.column
          .filter((v) => v.visible)
          .map((v) => {
            let rowData = v;
            if (rowData.field.indexOf('checkType') !== -1) {
              rowData = {
                ...rowData,
                fixed: 'left', // 固定位置在左边
                align: 'left',
                width: '36',
                type: rowData.field.replace('checkType', ''),
              };
            }
            return rowData;
          });
      }
    },
    // 获取品鉴酒表头1配置
    async getTastingColumns1() {
      const params = {
        parentCode: 'CRM20201126000000098',
        functionCode: 'tpm_act_details_list_1',
      };
      const res = await request.post('/mdm/mdmtableconfig/query', params);
      if (res.success) {
        this.columns = res.result.column
          .filter((v) => v.visible)
          .map((v) => {
            let rowData = v;
            if (rowData.field.indexOf('checkType') !== -1) {
              rowData = {
                ...rowData,
                fixed: 'left', // 固定位置在左边
                align: 'left',
                width: '36',
                type: rowData.field.replace('checkType', ''),
              };
            }
            return rowData;
          });
      }
    },
    // 获取品鉴酒表头2配置
    async getTastingColumns2() {
      const params = {
        parentCode: 'CRM20201126000000098',
        functionCode: 'tpm_act_details_list_2',
      };
      const res = await request.post('/mdm/mdmtableconfig/query', params);
      if (res.success) {
        this.columns1 = res.result.column
          .filter((v) => v.visible)
          .map((v) => {
            let rowData = v;
            if (rowData.field.indexOf('checkType') !== -1) {
              rowData = {
                ...rowData,
                fixed: 'left', // 固定位置在左边
                align: 'left',
                width: '36',
                type: rowData.field.replace('checkType', ''),
              };
            }
            return rowData;
          });
      }
    },

    // 明细选择列表
    openDetails() {
      const activityList = this.getFieldValue('tpmAuditActReqVos');

      if (!activityList.length) {
        this.$message.error('请先选择活动！');
      } else {
        const actCodes = activityList.map((v) => v.actCode);
        const notInCodeList = this.value ? this.value.map((v) => v.actDetailCode) : [];
        const params = {
          functionCode: 'tpm_act_details_list_select',
          data: [],
          selectionList: [],
          idKey: 'actDetailCode',
          paramData: {
            actCodes,
            notInCodeList,
          },
        };

        this.$refs.selectConfig.openSelectModal(params);
      }
    },
    // 活动选择数据回调
    async onGetSelect(val) {
      const list = val.map((v) => {
        /* eslint-disable */
        for (const item of v.payTypes) {
          if (item.dictValue === v.payType) {
            v.payTypeName = item.dictKey;
          }
        }
        const { id, ...params } = v;

        let subParams = {
          productLevelCode: '',
          productLevelName: '',
          replenishmentProductCodes: [],
          replenishmentProductList: [],
        };

        if (v.payType === '4') {
          const product = (v.replenishmentProductList || []).map((subItem) => {
            const { id: subId, ...subParam } = subItem;
            return subParam;
          });

          subParams = {
            ...subParams,
            productLevelCode: v.productLevelCode,
            productLevelName: v.productLevelName,
            replenishmentProductCodes: product.map((sub) => sub.productCode),
            replenishmentProductList: product,
            levelOptions: v.productLevelCode ? [{
              productLevelCode: v.productLevelCode,
              productLevelName: v.productLevelName,
            }] : [],
            productOptions: product,
          };
        }
        return {
          ...params,
          ...subParams,
          actDetailApplyAmount: v.applyAmount,
          alreadyAuditAmount: v.auditAmount,
          ext31: null,
          ext32: null,
          // ext60:null,
          ext61: null
        };
      });
      let tastingList=[]
      tastingList=val.map((m)=>{
        if (m.isOpen==='1') {
          return {
            // ...params,
            actCode:m.actCode,
            actName:m.actName,
            // auditDetailCode:m.auditDetailCode,
            actDetailCode:m.actDetailCode,
            actDetailName:m.ext1,
            customerName:m.ext7,
            customerCode:m.ext6,
            companyCode:m.ext2,
            companyName:m.ext3,
            payType:m.tastingPayType,
            productCode:m.tastingWineCode,
            productName:m.tastingWineName,
            tastingPrice:m.tastingPrice||0,
            tastingNum:m.tastingNumber,
            num:null,
            amount:null,
            isTarget:false,
          }
        }
      })
      let newTastingList=[]
      tastingList.forEach(item => {
        if (item) {
          newTastingList.push(item)
        }
    	})
      let isCompliance=list.some((m)=>m.ext10==='N')
      if(isCompliance){
        this.$message.error('所选活动明细被判定为不合规，请重新选择！');
        return false
      }
      console.log(isCompliance);
      this.$emit('input', (this.value || []).concat(list));
      this.$emit('changeTasting', (this.tastingReqVos || []).concat(newTastingList));
    },
    // 删除行数据
    removeRow(row, rowIndex) {
      const list = this.value.filter((v, k) => k !== rowIndex);
      const tastingList = this.tastingReqVos.filter((v, k) => k !== rowIndex);

      this.selectRow = this.selectRow.filter((v) => v.actCode !== row.actCode);

      this.$emit('input', list);
      this.$emit('changeTasting', tastingList);

      const fineCodes = list.map((v) => v.fineCode);

      if (!fineCodes.includes(row.fineCode)) {
        const auditFiles = this.getFieldValue('auditFileRespVos') || [];
        const punchReqVos = this.getFieldValue('punchReqVos') || [];
        this.$emit('setFielValue', {
          field: 'auditFileRespVos',
          val: auditFiles.filter((v) => v.fineCode !== row.fineCode),
        });
        this.$emit('setFielValue', {
          field: 'punchReqVos',
          val: punchReqVos.filter((v) => v.fineCode !== row.fineCode),
        });
      }
    },
    // 清除
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('请确认是否要清空当前数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('input', []);
          this.$emit('changeTasting', []);
          this.selectRow = [];
          this.$emit('setFielValue', { field: 'auditFileRespVos', val: [] });
          this.$emit('setFielValue', {field: 'punchReqVos',val: []});
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 全选
    checkboxAll({ records }) {
      this.selectRow = records;
    },
    // 多选
    checkboxChange({ records }) {
      this.selectRow = records;
    },
    // 下拉框获取数据
    async remoteMethod(type, index, query) {
      this.loading = true;
      let url = '/mdm/mdmProductLevelController/productLevelSelectList';
      let params = {
        enableStatus: '009',
        productLevelName: query || '',
      };

      if (type === 'pro') {
        const code = this.value[index].productLevelCode;
        url = '/mdm/mdmProductController/productSelectList';
        params = {
          enableStatus: '009',
          productName: query || '',
          productLevelCode: code || '',
        };
      }
      const res = await request.post(url, params);
      this.loading = false;

      if (res.success) {
        if (type === 'pro') {
          this.value[index].productOptions = res.result;
        }else {
          this.value[index].levelOptions = res.result;
        }
      }
    },
    // 层级修改
    levelChange(val, index) {
      const options = this.value[index].levelOptions.find((v) => v.productLevelCode === val);
      this.value[index].productLevelName = options ? options.productLevelName : '';
      this.value[index].replenishmentProductCodes = [];
      this.value[index].replenishmentProductList = [];
    },
    // 产品修改
    productChange(val, index) {
      const options = this.value[index].productOptions.filter((v) => val.includes(v.productCode));
      this.value[index].replenishmentProductList = options.map((subItem) => {
        const { id, ...subParam } = subItem;
        return subParam;
      });
    },
    // 核销资料
    openVerification(row) {
      this.modalConfig = {
        width: '60%',
        title: '上传核销资料',
        type: 'Modal',
        visible: false,
      };
      this.formConfig = {};
      this.formName = 'Material';
      if (this.value.length === 0) {
        this.$message.error('请先新增明细！');
      } else {
        const fineCodeList = row ? [row.fineCode] : this.value.map((v) => v.fineCode);
        const auditFiles = row ? row.auditFileRespVos : this.getFieldValue('auditFileRespVos');
        const punchReqVos = row ? row.punchReqVos : this.getFieldValue('punchReqVos');
        this.formConfig = {
          code: row ? 'cell_write_off' : 'write_off',
          disabled: this.disabled,
          row: {
            fineCodeList,
            auditFileReqVos: auditFiles ? auditFiles.filter((v) => v.auditFileType === 1) : [],
            punchReqVos: punchReqVos ? punchReqVos : [],
          },
        };
        this.modalConfig.visible = true;
      }
    },
    // 执行查看
    executionLookFn(row, index) {
      console.log(row)
      this.chooseIndex = index;
      this.formName = 'Execution';
      this.modalConfig = {
        title: '查看执行资料',
        visible: true,
        width: '80%',
        type: 'Drawer',
      };
      this.formConfig = {
        code: this.code,
        row,
        // fineCode: 'diplay',
      };
    },
    // 扫码记录
    showModal(row, index) {
      this.chooseIndex = index;
      this.formName = 'ScanRecord';
      this.modalConfig = {
        title: '扫码记录',
        visible: true,
        width: '80%',
        type: 'Drawer',
      };
      this.formConfig = {
        code: this.code,
        row,
        auditId:this.auditId
      };
      this.isScan = false
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
      this.chooseData = [];
      this.isScan = true
    },
    setPersonValue(list){
      if (this.formName !== 'Material') return false;
      if (this.formConfig.code === 'write_off') {
        this.$emit('setFielValue', { field: 'punchReqVos', val: list });
      } else {
        let _value = JSON.parse(JSON.stringify(this.value));
        _value = _value.map((item) => {
          const v = item;
          if (v.fineCode === this.formConfig.row.fineCodeList[0]) {
            v.punchReqVos = list;
          }
          return v;
        });
        this.$emit('input', _value);
      }
    },
    // 设置核销资料
    setFielValue(list) {
      if (this.formName !== 'Material') return false;
      if (this.formConfig.code === 'write_off') {
        this.$emit('setFielValue', { field: 'auditFileRespVos', val: list });
      } else {
        let _value = JSON.parse(JSON.stringify(this.value));
        _value = _value.map((item) => {
          const v = item;
          if (v.fineCode === this.formConfig.row.fineCodeList[0]) {
            v.auditFileRespVos = list;
          }
          return v;
        });
        this.$emit('input', _value);
      }
    },
    // 获取品鉴用酒支付方式
    getTastingPayType() {
     request.post(
        "/mdm/mdmdictdata/list",
        {
           dictTypeCode: 'tasting_pay_type',
        }
      ).then((res) => {
          if (res.success) {
            this.tastingPayTypeList = res.result;
          }
        });
    },
    // 支付方式监听
    payTypeChange(i) {
      const list = JSON.parse(JSON.stringify(this.value));
      list[i] = {
        ...list[i],
        productLevelCode: '',
        productLevelName: '',
        replenishmentProductCodes: [],
        replenishmentProductList: [],
      };
      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scepod>
.red {
  color: #f56c6c !important;
  margin-left: 10px;
}
.tasting{
  margin-top: 20px;
}
</style>
